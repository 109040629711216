import { FC, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import { roundImageDimension } from '@utils'
import { Text, CustomLinkTag } from '@components'
import { Parallax } from 'react-parallax'
import { Button, Container, Row, Col, VideoPlayer, Icon } from '@mch-group/uikit-components'
import { nanoid } from 'nanoid'
import { Media, MediaContextProvider } from '@responsive'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { convertHexToRgba } from '@utils/cssHelpers'

const EditorialModule: FC<IEditorial> = ({
  editorial_bg_color = 'light grey',
  editorial_cta,
  editorial_desktop_img,
  editorial_media_priority = 'image',
  //editorial_mobile_img,
  editorial_video,
  editorial_sub_title,
  editorial_text,
  editorial_text_container_side = 'left',
  editorial_title,
  editorial_cta_link,
  editorial_space_from_bottom,
  editorial_space_from_top,
  id,
  moduleName
}) => {
  const cloudinaryName = getEnvConfig.fe.cloudinary.name
  const [imageSize, setImageSize] = useState(480)
  const [playing, setPlaying] = useState(false)
  const setImageOnPageLOad = useCallback(() => {
    if (typeof document !== 'undefined') {
      //@ts-ignore
      const el = document.getElementById(id)
      el !== null && setImageSize(roundImageDimension(el.clientHeight, 100))
    }
  }, [id])

  const onClickVideoCover = (evt) => {
    //@ts-ignore
    if (VideoPlayer && editorial_video && editorial_media_priority === 'video') {
      setPlaying(evt)
    }
  }

  useEffect(() => {
    setImageOnPageLOad()
  }, [setImageOnPageLOad])

  return (
    <StyledEditorial className={classNames(`${moduleName} cms-module`, {
      'mt-0': editorial_space_from_top === 'true',
      'mb-0': editorial_space_from_bottom === 'true'
      //@ts-ignore
    })} id={id}>
      <Container className='px-5 px-lg-8 px-md-7'>
        <Row
          className={classNames('m-0', {
            [`bkg-${editorial_bg_color?.replace(' ', '-')}`]: editorial_bg_color,
            [`media-${editorial_media_priority}`]: editorial_media_priority,
            [`text-${editorial_text_container_side}`]: editorial_text_container_side,
            'mt-0': editorial_space_from_top === 'true',
            'mb-0': editorial_space_from_bottom === 'true'
          })}
        >
          <Col
            className='col-text m-0 p-0'
            md={12}
            lg={editorial_media_priority === 'video' ? 5 : 6}
            xl={editorial_media_priority === 'video' ? 5 : 6}
            xxl={editorial_media_priority === 'video' ? 5 : 6}
          >
            <div className='col-text-wrapper'>
              {editorial_title && <h2 className='mb-7 mb-lg-5'>{editorial_title}</h2>}
              {editorial_sub_title && <h3 className='mb-2 h5'>{editorial_sub_title}</h3>}
              {editorial_text && (
                <Text isHtml className={`mb-last-p ${editorial_cta && editorial_cta_link ? 'mb-8' : ''}`}>
                  {editorial_text}
                </Text>
              )}
              {editorial_cta_link && editorial_cta && (
                <CustomLinkTag url={editorial_cta_link}>
                  <Button variant='primary' theme={editorial_bg_color === 'dark' ? 'dark' : 'light'}>
                    {editorial_cta}
                  </Button>
                </CustomLinkTag>
              )}
            </div>
          </Col>
          <Col
            className='col-image m-0 p-0'
            md={12}
            lg={editorial_media_priority === 'video' ? 7 : 6}
            xl={editorial_media_priority === 'video' ? 7 : 6}
            xxl={editorial_media_priority === 'video' ? 7 : 6}
          >
            {editorial_media_priority === 'image' && editorial_desktop_img && (
              <MediaContextProvider>
                <Media greaterThanOrEqual='computer'>
                  <Parallax
                    bgImage={uploadTransformationUrl(editorial_desktop_img, 'w_1200,q_auto:good,dpr_1.0,c_crop,c_fill,g_auto,f_auto',
                      { 'transformUrl': cloudinary.throughCloudfront })}
                    bgImageAlt='the cat'
                    strength={250}
                  >
                    <div style={{ height: 500 }} />
                  </Parallax>
                </Media>
                <Media lessThan='computer'>
                  <div
                    className='editorial-image'
                    style={{
                      backgroundImage: `url("${uploadTransformationUrl(editorial_desktop_img, `q_auto,h_${imageSize},f_auto`, {
                        transformUrl: cloudinary.throughCloudfront
                      })}")`
                    }}
                  />
                </Media>
              </MediaContextProvider>
            )}
            {editorial_media_priority === 'video' && editorial_video && (
              <div className='video-wrapper'>
                {editorial_desktop_img ? (
                  <div
                    className={`video-cover${playing ? ' is-playing' : ''}`}
                    onClick={() => onClickVideoCover(true)}
                    onKeyDown={() => onClickVideoCover(true)}
                    role='button'
                    tabIndex={0}
                    style={{
                      backgroundImage: `url("${uploadTransformationUrl(editorial_desktop_img, `q_auto,h_${imageSize}`, {
                        transformUrl: cloudinary.throughCloudfront
                      })}")`
                    }}
                  >
                    <div className='video-cover-overlay'>
                      <Icon name='play' height={40} width={40} color={tokens.color.base.primary.white.value} />
                    </div>
                  </div>
                ) : (
                  <div className='video-no-cover' />
                )}
                <VideoPlayer
                  playing={playing}
                  controls={true}
                  loop={false}
                  muted={false}
                  startOnMouseEnter={false}
                  playsinline
                  key={nanoid()}
                  //@ts-ignore
                  setPlaying={(evt) => setPlaying(evt)} /* to be removed */
                  url={cloudinary.throughCloudfront(
                    `https://res.cloudinary.com/${cloudinaryName}/video/upload/vc_h264/${editorial_video}.m3u8`
                  )}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </StyledEditorial>
  )
}

const StyledEditorial = styled.section`
  .bkg {
    &-light-grey {
      background: var(--bs-mch-gray-200);
    }

    &-dark {
      background: var(--bs-mch-black);

      .col-text-wrapper {
        & > h2,
        & > h5,
        & > div {
          color: var(--bs-mch-white);
        }
      }
    }
  }

  .editorial-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .video {
    &-wrapper {
      .player-wrapper {
        overflow: hidden;
      }

      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        position: relative;

        .player-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          z-index: 0;

          video {
            object-fit: cover;
          }
        }
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        background: var(--bs-mch-black);
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    &-no-cover {
      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-cover {
      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;
        position: relative;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;

        &.is-playing {
          visibility: hidden;
        }
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;

        &.is-playing {
          visibility: hidden;
        }
      }

      &-overlay {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[1000].value, tokens.opacity[30].value)});
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .text {
    &-left {
      .col-text {
        order: 1;

        @media (max-width: ${global.uikitVars.breakpointMdMax}) {
          order: 2;
        }
      }

      .col-image {
        order: 2;
        position: relative;
        overflow: hidden;

        @media (max-width: ${global.uikitVars.breakpointMdMax}) {
          order: 1;
        }
      }

      .react-parallax {
        position: static !important;
        overflow: visible !important;
      }
    }

    &-right {
      .col-text {
        order: 2;
      }

      .col-image {
        order: 1;
      }
    }
  }

  .col-image {
    @media (max-width: ${global.uikitVars.breakpointMdMax}) {
      .editorial-image {
        width: 100%;
        height: 100%;
        padding-bottom: 66.66%;
      }
    }
  }

  .col-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: none;
    order: 0;
    flex-grow: 0;

    @media (min-width: 0) and (max-width: ${global.uikitVars.breakpointSmMax}) {
      padding: var(--spacing-8) var(--spacing-5);
    }

    @media (min-width: 768px) and (max-width: ${global.uikitVars.breakpointMdMax}) {
      padding: var(--spacing-11) var(--spacing-7);
    }

    @media (min-width: ${global.uikitVars.breakpointLg}) and (max-width: ${global.uikitVars.breakpointLgMax}) {
      min-height: 480px;
      padding: var(--spacing-14) var(--spacing-8);
    }

    @media (min-width: 1280px) {
      min-height: 480px;
      padding: var(--spacing-14);
    }

    .mb-last-p p:last-child {
      margin-bottom: 0;
    }
  }
`

interface IEditorial {
  id: string | undefined | number
  moduleName: string
  editorial_bg_color: string
  editorial_cta: string
  editorial_desktop_img: string
  editorial_media_priority: string
  editorial_mobile_img: string
  editorial_sub_title: string
  editorial_text: string
  editorial_text_container_side: string
  editorial_title: string
  editorial_video: string
  editorial_cta_link: string
  editorial_space_from_top: string
  editorial_space_from_bottom: string
}

export default EditorialModule
